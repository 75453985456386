/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';

const KmmWhatsGive: FC = () => {
  const prefix = usePathPrefix();

  const cardIcons = [
    `${prefix}/case/kmmPage/icons/economy.svg`,
    `${prefix}/case/kmmPage/icons/ios_android_kmm.svg`,
    `${prefix}/case/kmmPage/icons/time.svg`,
    `${prefix}/case/kmmPage/icons/productive.svg`,
  ];

  return (
    <div className={styles.wrap}>
      <h2>{t('cases.kmmPage.kmmWhatsGive.title')}</h2>
      <div className={styles.cards}>
        {cardIcons.map((card, index) => (
          <div className={styles.card} key={`cardWg-${index + 1}`}>
            <img src={card} alt="" />
            <p>{t(`cases.kmmPage.kmmWhatsGive.cards.card_${index + 1}`)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export { KmmWhatsGive };
