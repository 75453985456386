/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';
import { t } from '../../../../../i18n';
import 'tiny-slider/src/tiny-slider.scss';
import { Slider } from '../../../../common/Slider';
import { KmmStep } from '../../components/KmmStep';
import { KmmCardInfo } from '../../components/KmmCardInfo';
import { KmmButton } from '../../components/KmmButton';

interface IProps {}

const KmmTransition: FC<IProps> = () => {
  const prefix = usePathPrefix();

  const scrollToForm = () => {
    const formElement = document.getElementById('form');
    formElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const sliderProps = {
    gutter: 24,
    nav: false,
    loop: false,
    lazyload: false,
    controls: true,
    items: 1,
    swipeAngle: false,
    autoHeight: true,
  };

  const iconsUrl = [
    `${prefix}/case/kmmPage/icons/trans_price.svg`,
    `${prefix}/case/kmmPage/icons/trans_plan.svg`,
    `${prefix}/case/kmmPage/icons/trans_app.svg`,
    `${prefix}/case/kmmPage/icons/trans_integration.svg`,
  ];

  return (
    <div className={classNames(styles.app)}>
      <div className={styles.header_wrap}>
        <div className={styles.title_wrap}>
          <h2>{t('cases.kmmPage.kmmTransition.title')}</h2>
        </div>
      </div>

      <div className={styles.carousel}>
        <Slider
          color="#55439D"
          className={styles.carousel__slider}
          sliderProps={sliderProps}
        >
          <div className={styles.carousel__slide}>
            <p className={styles.carousel__label}>
              {t('cases.kmmPage.kmmTransition.cases.case_1.desc')}
            </p>
            <div className={styles.case}>
              <div className={styles.steps}>
                {[...Array(5)].map((item, index) => (
                  <KmmStep
                    key={`step-${index + 1}`}
                    stepNumber={index + 1}
                    stepName={`cases.kmmPage.kmmTransition.cases.case_1.steps.${index}`}
                  />
                ))}
              </div>
              <div className={styles.cards}>
                {iconsUrl
                  .filter((item) => item !== iconsUrl[2])
                  .map((item, index) => (
                    <KmmCardInfo
                      key={item}
                      iconUrl={item}
                      name={`cases.kmmPage.kmmTransition.cases.case_1.cards.card_${
                        index + 1
                      }.name`}
                      desc={`cases.kmmPage.kmmTransition.cases.case_1.cards.card_${
                        index + 1
                      }.desc`}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.carousel__slide}>
            <p className={styles.carousel__label}>
              {t('cases.kmmPage.kmmTransition.cases.case_2.desc')}
            </p>
            <div className={styles.case}>
              <div className={styles.steps}>
                {[...Array(5)].map((item, index) => (
                  <KmmStep
                    key={`step-${index + 1}`}
                    stepNumber={index + 1}
                    stepName={`cases.kmmPage.kmmTransition.cases.case_2.steps.${index}`}
                  />
                ))}
              </div>
              <div className={styles.cards}>
                {iconsUrl
                  .filter(
                    (item) => item !== iconsUrl[2] && item !== iconsUrl[3]
                  )
                  .map((item, index) => (
                    <KmmCardInfo
                      key={item}
                      iconUrl={item}
                      name={`cases.kmmPage.kmmTransition.cases.case_2.cards.card_${
                        index + 1
                      }.name`}
                      desc={`cases.kmmPage.kmmTransition.cases.case_2.cards.card_${
                        index + 1
                      }.desc`}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.carousel__slide}>
            <p className={styles.carousel__label}>
              {t('cases.kmmPage.kmmTransition.cases.case_3.desc')}
            </p>
            <div className={styles.case}>
              <div className={styles.steps}>
                {[...Array(5)].map((item, index) => (
                  <KmmStep
                    key={`step-${index + 1}`}
                    stepNumber={index + 1}
                    stepName={`cases.kmmPage.kmmTransition.cases.case_3.steps.${index}`}
                  />
                ))}
              </div>
              <div className={styles.cards}>
                {iconsUrl.map((item, index) => (
                  <KmmCardInfo
                    key={item}
                    iconUrl={item}
                    name={`cases.kmmPage.kmmTransition.cases.case_3.cards.card_${
                      index + 1
                    }.name`}
                    desc={`cases.kmmPage.kmmTransition.cases.case_3.cards.card_${
                      index + 1
                    }.desc`}
                  />
                ))}
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <KmmButton onClick={scrollToForm} bigSize>
        {t('cases.kmmPage.kmmTransition.button')}
      </KmmButton>
    </div>
  );
};

export { KmmTransition };
