/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';
import { t } from '../../../../../i18n';
import { Slider } from '../../../../common/Slider';
import useScreen from '../../../../../utils/hooks/useScreen';
import { KmmCardInfo } from '../../components/KmmCardInfo';

type IProps = {
  // reviews: IReviewsQuery['reviews']['edges'];
  // locale: string;
};

const KmmWeDidIt: FC<IProps> = () => {
  const prefix = usePathPrefix();
  const { isMobileSmall } = useScreen();

  const cardImg = [
    {
      id: 'Getmega',
      img: [
        `${prefix}/case/kmmPage/caseimg/getmega_1.jpg`,
        `${prefix}/case/kmmPage/caseimg/getmega_2.jpg`,
        `${prefix}/case/kmmPage/caseimg/getmega_3.jpg`,
        `${prefix}/case/kmmPage/caseimg/getmega_4.jpg`,
      ],
      icons: [
        `${prefix}/case/kmmPage/icons/wdi_planning.svg`,
        `${prefix}/case/kmmPage/icons/wdi_timing.svg`,
        `${prefix}/case/kmmPage/icons/wdi_price.svg`,
      ],
    },
    {
      id: 'Footballco',
      img: [
        `${prefix}/case/kmmPage/caseimg/footballco_1.jpg`,
        `${prefix}/case/kmmPage/caseimg/footballco_2.jpg`,
        `${prefix}/case/kmmPage/caseimg/footballco_3.jpg`,
        `${prefix}/case/kmmPage/caseimg/footballco_4.jpg`,
      ],
      icons: [
        `${prefix}/case/kmmPage/icons/wdi_planning.svg`,
        `${prefix}/case/kmmPage/icons/wdi_price.svg`,
      ],
    },
    {
      id: 'Profi',
      img: [
        `${prefix}/case/kmmPage/caseimg/profi_1.jpg`,
        `${prefix}/case/kmmPage/caseimg/profi_2.jpg`,
        `${prefix}/case/kmmPage/caseimg/profi_3.jpg`,
        `${prefix}/case/kmmPage/caseimg/profi_4.jpg`,
      ],
      icons: [
        `${prefix}/case/kmmPage/icons/wdi_planning.svg`,
        `${prefix}/case/kmmPage/icons/wdi_timing.svg`,
      ],
    },
  ];

  return isMobileSmall ? (
    <div className={styles.wrap}>
      <h2>{t('cases.kmmPage.kmmWeDidIt.title')}</h2>
      {cardImg.map((card, index) => (
        <div className={styles.item} key={card.id}>
          <div className={styles.case}>
            <div className={styles.header}>
              <h3>
                {t(`cases.kmmPage.kmmWeDidIt.cases.case_${index + 1}.title`)}
              </h3>
            </div>
            <div className={styles.content}>
              <div className={styles.left}>
                <img src={card.img[0]} alt="" />
              </div>
              <div className={styles.right}>
                <div className={styles.content_text}>
                  <h4>
                    {t(
                      `cases.kmmPage.kmmWeDidIt.cases.case_${
                        index + 1
                      }.subTitle`
                    )}
                  </h4>
                  {t(`cases.kmmPage.kmmWeDidIt.cases.case_${index + 1}.desc`)}
                </div>
                <div className={styles.images_wrap}>
                  <Slider
                    sliderProps={{
                      items: 1,
                      mouseDrag: true,
                      slideBy: 'page',
                      gutter: 20,
                      lazyload: false,
                      swipeAngle: false,
                      loop: false,
                      controls: false,
                      nav: false,
                    }}
                    containerClassName={styles.sliderContainer}
                    className={styles.slider}
                    color="#0062FF"
                  >
                    {card.img.map((img, i) => (
                      <div className={styles.item} key={`cell-${i + 1}`}>
                        <div className={styles.cell}>
                          <img src={img} alt="" />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
            <div className={styles.cards}>
              {card.icons.map((icon, idx) => (
                <KmmCardInfo
                  key={`cardInfo-${idx + 1}`}
                  iconUrl={icon}
                  name={`cases.kmmPage.kmmWeDidIt.cases.case_${
                    index + 1
                  }.cards.card_${idx + 1}.name`}
                  desc={`cases.kmmPage.kmmWeDidIt.cases.case_${
                    index + 1
                  }.cards.card_${idx + 1}.desc`}
                  blackType
                />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className={styles.wrap}>
      <Slider
        sliderProps={{
          items: 1,
          mouseDrag: true,
          slideBy: 'page',
          gutter: 20,
          lazyload: false,
          swipeAngle: false,
          loop: false,
          controls: false,
          autoHeight: true,
        }}
        containerClassName={styles.sliderContainer}
        className={styles.slider}
        color="#0062FF"
      >
        {cardImg.map((card, index) => (
          <div className={styles.item} key={card.id}>
            <div className={styles.case}>
              <div className={styles.header}>
                <h2>{t('cases.kmmPage.kmmWeDidIt.title')}</h2>
                <h3>
                  {t(`cases.kmmPage.kmmWeDidIt.cases.case_${index + 1}.title`)}
                </h3>
              </div>
              <div className={styles.content}>
                <div className={styles.left}>
                  <img src={card.img[0]} alt="" />
                </div>
                <div className={styles.right}>
                  <div className={styles.content_text}>
                    <h4>
                      {t(
                        `cases.kmmPage.kmmWeDidIt.cases.case_${
                          index + 1
                        }.subTitle`
                      )}
                    </h4>
                    {t(`cases.kmmPage.kmmWeDidIt.cases.case_${index + 1}.desc`)}
                  </div>
                  <div className={styles.images_wrap}>
                    {card.img.map((img, i) => (
                      <div className={styles.cell} key={`cell-${i + 1}`}>
                        <img src={img} alt="" />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.cards}>
                {card.icons.map((icon, idx) => (
                  <KmmCardInfo
                    key={`cardInfo-${idx + 1}`}
                    iconUrl={icon}
                    name={`cases.kmmPage.kmmWeDidIt.cases.case_${
                      index + 1
                    }.cards.card_${idx + 1}.name`}
                    desc={`cases.kmmPage.kmmWeDidIt.cases.case_${
                      index + 1
                    }.cards.card_${idx + 1}.desc`}
                    blackType
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export { KmmWeDidIt };
