/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';
import { KmmBreadcrumbs } from '../../components/KmmBreadcrumbs';
import { KmmButton } from '../../components/KmmButton';

interface IProps {
  title: string;
}

const KmmTitle: FC<IProps> = ({ title }) => {
  const prefix = usePathPrefix();

  const scrollToForm = () => {
    const formElement = document.getElementById('form');
    formElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };
  return (
    <div
      className={styles.wrap}
      style={{ backgroundImage: `url(${prefix}/case/kmmPage/background.png)` }}
    >
      <div
        className={styles.kmm_logo}
        style={{
          backgroundImage: `url(${prefix}/case/kmmPage/kmm_title_logo.png)`,
        }}
      />
      <div className={styles.breadcrumbs}>
        <KmmBreadcrumbs title={title} />
      </div>
      <div className={styles.icerock_logo}>
        <img src={`${prefix}/case/kmmPage/icerock_logo.svg`} alt="icerockdev" />
      </div>

      <div className={styles.title_content}>
        <div className={styles.title_text}>
          <h1>
            {t('cases.kmmPage.kmmHeader.title')}{' '}
            <span className={styles.bigger}>
              {t('cases.kmmPage.kmmHeader.titleBigger')}
            </span>
          </h1>
          <p>{t('cases.kmmPage.kmmHeader.desc')}</p>
          <KmmButton onClick={scrollToForm}>
            {t('cases.kmmPage.kmmHeader.button')}
          </KmmButton>
        </div>
      </div>
    </div>
  );
};

export { KmmTitle };
