/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../../../constants/hooks';

const KmmIntegrate: FC = () => {
  const prefix = usePathPrefix();

  const cardIcons = [
    `${prefix}/case/kmmPage/kmm_others_platform.svg`,
    `${prefix}/case/kmmPage/kmm_logic.svg`,
    `${prefix}/case/kmmPage/kmm_android_to_ios.svg`,
  ];

  return (
    <div className={styles.wrap}>
      <h2>{t('cases.kmmPage.kmmIntegrate.title')}</h2>
      <div className={styles.cards}>
        <div
          className={styles.border}
          style={{ backgroundImage: `url(${prefix}/case/kmmPage/border.svg)` }}
        />
        {cardIcons.map((item, index) => (
          <div className={styles.card} key={`card-${index + 1}`}>
            <img src={item} alt="" />
            <p>{t(`cases.kmmPage.kmmIntegrate.cards.card_${index + 1}`)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export { KmmIntegrate };
