import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import { MainLayout } from '../../../../templates/MainLayout';
import { IDirectionTagsQuery } from '../../../../queries/directionTags/types';
import { KmmTitle } from '../../../../components/directions/kmmPage/screens/KmmTitle';
import { KmmIntegrate } from '../../../../components/directions/kmmPage/screens/KmmIntegrate';
import { KmmForWhom } from '../../../../components/directions/kmmPage/screens/KmmForWhom';
import { KmmWhatsGive } from '../../../../components/directions/kmmPage/screens/KmmWhatsGive';
import { KmmTransition } from '../../../../components/directions/kmmPage/screens/KmmTransition';
import { KmmDirectionsForm } from '../../../../components/directions/kmmPage/screens/KmmDirectionsForm';
import { KmmWeDidIt } from '../../../../components/directions/kmmPage/screens/KmmWeDidIt';
import { ReCaptchaProvider } from '../../../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  IDirectionTagsQuery,
  { locale: string; tag: string; page: number }
>;

const KmmPage: FC<IProps> = ({
  data,
  location,
  pageContext: { locale = 'en', tag = '', page = 0 },
}) => (
  <ReCaptchaProvider>
    <MainLayout
      locale={locale}
      title="Kotlin multiplatform software development by IceRock"
      description="Kotlin Multiplatform Mobile KMM software development by IceRock"
      keywords="kmm, kotlin multiplatform, icerock, app development, mobile, ios, android, custom, fintech"
    >
      <KmmTitle title="KMM" />
      <KmmIntegrate />
      <KmmForWhom />
      <KmmWhatsGive />
      <KmmTransition />
      <KmmWeDidIt />
      <KmmDirectionsForm />
    </MainLayout>
  </ReCaptchaProvider>
);

export default KmmPage;
