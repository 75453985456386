import React, { FC, Fragment, ReactElement } from 'react';
import { useLocation } from '@reach/router';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { TLink } from '../../../../../i18n/TLink';
import { useFromBreadcrumbs } from '../../../../../constants/hooks';

interface IProps {
  title: string | ReactElement | null;
}

const KmmBreadcrumbs: FC<IProps> = ({ title }) => {
  let breadcrumbs = useFromBreadcrumbs();
  const location = useLocation();
  if (location.pathname.indexOf('directions') !== -1) {
    breadcrumbs = [{ title: 'menu.directions', url: '/directions' }];
  }

  return (
    <div className={styles.breadcrumbs}>
      {breadcrumbs.map((item, i) => (
        <Fragment key={item.url}>
          <div className={styles.breadcrumbs__item}>
            <TLink to={item.url}>{t(item.title)}</TLink>
          </div>

          <div className={styles.breadcrumbs__separator}>/</div>
        </Fragment>
      ))}

      <div className={styles.breadcrumbs__current}>{title}</div>
    </div>
  );
};

export { KmmBreadcrumbs };
