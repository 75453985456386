import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

interface IProps {
  iconUrl: string;
  desc: string;
  name: string;
  blackType?: boolean;
}

const blackStyles = {
  backgroundColor: '#23222C',
};

const textStyles = {
  fontSize: '12px',
  lineHeight: '22px',
  fontWeight: 700,
};

const KmmCardInfo: FC<IProps> = ({ iconUrl, desc, name, blackType }) => (
  <div className={styles.card} style={blackType ? blackStyles : undefined}>
    <div className={styles.icon}>
      <img src={iconUrl} alt="" />
    </div>
    <div className={styles.info} style={blackType ? textStyles : undefined}>
      {t(name)}
      <p>{t(desc)}</p>
    </div>
  </div>
);

export { KmmCardInfo };
