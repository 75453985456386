import React, { FC } from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';

interface IProps {
  stepNumber: string | number;
  stepName: string;
}

const KmmStep: FC<IProps> = ({ stepName, stepNumber }) => (
  <div className={styles.step}>
    <div className={styles.stepNumber}>{stepNumber}</div>
    <div className={styles.stepName}>{t(stepName)}</div>
  </div>
);

export { KmmStep };
